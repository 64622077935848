import React from "react";
import "./styles.css";

export default function App() {
    return (
        <div className="App">

            <iframe   src="https://drive.google.com/file/d/14lX7aEjESJXZgIBN0M3m48v23Q71k1Zg/preview" width="1240"
                    height="1080" allow="autoplay" frameBorder="0" allowtransparency="true"></iframe>
        </div>
    );
}
